import { useLocation } from 'react-router-dom';

import './LinearProgressbar.css';

const LinearProgressbar = ({ progress }) => {
  const location = useLocation();

  const pathname = location.pathname;

  return (
    pathname.includes('/question') && (
      <div className="progress-bar-container">
        <div className="progress">
          <div
            style={{ opacity: progress >= 0 ? 1 : 0.2 }}
            className={`progress-bar-20 ${progress >= 0 &&
              'progress-bar-striped progress-bar-animated'
              }`}
            role="progressbar"
          />
          <div
            style={{ opacity: progress >= 10 ? 1 : 0.2 }}
            className={`progress-bar-20 ${progress >= 10 &&
              'progress-bar-striped progress-bar-animated'
              }`}
            role="progressbar"
          />
          <div
            style={{ opacity: progress >= 20 ? 1 : 0.2 }}
            className={`progress-bar-40 ${progress >= 20 &&
              'progress-bar-striped progress-bar-animated'
              }`}
            role="progressbar"
          />
          <div
            style={{ opacity: progress >= 30 ? 1 : 0.2 }}
            className={`progress-bar-40 ${progress >= 30 &&
              'progress-bar-striped progress-bar-animated'
              }`}
            role="progressbar"
          />
          <div
            style={{ opacity: progress >= 40 ? 1 : 0.2 }}
            className={`progress-bar-60 ${progress >= 40 &&
              'progress-bar-striped progress-bar-animated'
              }`}
            role="progressbar"
          />
          <div
            style={{ opacity: progress >= 50 ? 1 : 0.2 }}
            className={`progress-bar-60 ${progress >= 50 &&
              'progress-bar-striped progress-bar-animated'
              }`}
            role="progressbar"
          />
          <div
            style={{ opacity: progress >= 60 ? 1 : 0.2 }}
            className={`progress-bar-80 ${progress >= 60 &&
              'progress-bar-striped progress-bar-animated'
              }`}
            role="progressbar"
          />
          <div
            style={{ opacity: progress >= 70 ? 1 : 0.2 }}
            className={`progress-bar-80 ${progress >= 70 &&
              'progress-bar-striped progress-bar-animated'
              }`}
            role="progressbar"
          />
          <div
            style={{ opacity: progress >= 80 ? 1 : 0.2 }}
            className={`progress-bar-100 ${progress >= 80 &&
              'progress-bar-striped rogress-bar-animated'
              }`}
            role="progressbar"
          />
          <div
            style={{ opacity: progress >= 90 ? 1 : 0.2 }}
            className={`progress-bar-100 ${progress >= 90 &&
              'progress-bar-striped progress-bar-animated'
              }`}
            role="progressbar"
          />
        </div>
      </div>
    )
  );
};

export default LinearProgressbar;
