import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./Principale.css";
import "./SplashScreen.css";
import Loader from "../../assets/Loader.png"
import splashTop from "./splashTop.svg"
import downWave from "./downWave.svg"
import { getToken } from "../../services/Controller/AuthService";
import axios from "axios";
import { ACTIVE_EVENT, SERVER_API } from "../../api";

const Principale = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [activeEvent, setActiveEvent] = useState(null);

  const token = getToken()

  const toRegister = () => {
    if (token) {
      navigate("/scan");
    } else {
      navigate("/register");
    }
  };

  const getActiveEvent = async () => {
    await axios.get(SERVER_API + ACTIVE_EVENT).then((res) => {
      setActiveEvent(res.data[0])
    })
  }

  useEffect(() => {
    getActiveEvent()
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    loading ? <div className="loader">
      <img src={Loader} className="circle" alt="loader" />
    </div> :
      <div className='splashContainer'>
        <div className="splashHeader">
          <div className="svgContainer">
            <img className="splashTop" src={splashTop} alt="" />
            <div className="yellowSquare"></div>
            <img className="downWave" src={downWave} alt="" />
          </div>
        </div>
        {/* Logo_IFEFO_Animation */}
        <img className='firstLogo' src={require('../../assets/Logo_IFEFO.gif')} alt="logo IFEFO" />
        {
          activeEvent ?
            <div className='sponsors'>
              <div className="uploaded-logo ifef-logo">{activeEvent?.logoLeft && <img src={SERVER_API + "uploads/" + activeEvent?.logoLeft} alt={activeEvent?.title} />}</div>
              <div className="uploaded-logo oif-logo">{activeEvent?.logoRight && <img src={SERVER_API + "uploads/" + activeEvent?.logoRight} alt={activeEvent?.title} />}</div>
            </div> :
            <div className='sponsors'>
              <img className="oops" src={require('../../assets/Logo_IFEF.png')} alt={activeEvent?.title} />
            </div>
        }
        <div className="main">
          <div className="flex-div-1">
            <div className="splash">
              <div className="title-principale">
                <div>Bienvenue<br/>IFEFo</div>
              </div>
              <div className="text-principale">
                Teste tes connaissances sur les enjeux de l’éducation dans l’espace francophone !

              </div>
              <div className="text-principale">
                Chaque question correcte t’amènera plus loin dans l’aventure et une chance d’entrer au tirage au sort.

              </div>
            </div>
            <div>
              {
                activeEvent ?
                  <Button variant="primary" size="lg" className="button-principale firstOne pointer" onClick={toRegister}>
                    ENTRE DANS LE JEU !
                  </Button> :
                  <Button variant="primary" size="lg" className="button-principale firstOne pointer">
                    Aucun événement actif
                  </Button>
              }
            </div>
          </div>
        </div>

      </div>
  );
}

export default Principale