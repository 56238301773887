import { BiArrowBack } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import './Acceptation.css';

const Acceptation = () => {
  const navigate = useNavigate();

  const toRegister = () => {
    navigate(-1);
  };

  return (
    <Container className='container-padding padding-main'>
      <BiArrowBack onClick={toRegister} className='backArrow' />
      <div className='sub-title'>
        Règlement du jeu numérique <br />« IFEFo »
      </div>
      <div className='text-title'>Article 1</div>
      <div className='text-style'>
        L’Institut de la Francophonie pour l’éducation et la formation lance un
        jeu numérique en français sur des sujets autour de la Francophonie et
        ses domaines d’intervention. Le jeu a une capacité potentielle de 500
        participants en simultané.
      </div>
      <div className='text-title'>Article 2</div>
      <div className='text-style'>
        Le jeu numérique « IFEFo » est ouvert pour les internautes francophones,
        francophiles ou capables de lire et comprendre la langue française.
      </div>
      <div className='text-title'>Article 3</div>
      <div className='text-style'>
        L’accès à l’application se fait uniquement en scannant le code QR
        prévu à cet effet ou en accédant au lien www.ifefo.app.
      </div>

      <div className='text-title'>Article 4</div>
      <div className='text-style'>
        Le jeu numérique « IFEFo » est un parcours journalier de 10 questions.
        Les participants sont invités à répondre aux questions réparties sur 5
        niveaux. Derrière chaque niveau, se trouve deux questions à choix
        multiples à laquelle le participant doit répondre pour avancer dans le
        jeu.
      </div>
      <div className='text-title'>Article 5</div>

      <div className='text-style'>
        La participation au jeu se fait uniquement à partir du formulaire
        d’inscription disponible sur l’application. Les participants sont tenus
        de renseigner le formulaire avec des informations correctes et
        vérifiables. Les adresses électroniques seront utilisées pour l’envoi de
        message de confirmation de l&#39;éligibilité au tirage au sort. Il est
        de la responsabilité des participants de s’assurer que l’adresse
        électronique indiquée est correcte. Toute inscription avec des
        informations erronées ou envoyées hors des périodes de jeu ne sera pas
        prise en compte.
      </div>
      <div className='text-title'>Article 6</div>

      <div className='text-style'>
        Pour chaque réponse correcte, le participant obtient des points qui lui
        permettront d’être éligible à un tirage au sort organisé par l’IFEF
        (OIF) à conditions que son score final soit égale ou supérieur à 50
        points. Les participants autorisent l’IFEF (OIF) à publier tout ou une
        partie des informations les concernant suivant le Règlement (UE)
        2016/679 du Parlement européen et du Conseil du 27 avril 2016, relatif à
        la protection des personnes physiques à l&#39;égard du traitement des
        données à caractère personnel et à la libre circulation de ces données.
      </div>
      <div className='text-title'>Article 7</div>

      <div className='text-style'>
        Les personnes éligibles au tirage au sort sont informées qu’à la fin du
        parcours. Des détails concernant le lieu et l’heure du tirage au sort
        seront également communiqués dans l’application. Seules les personnes
        éligibles au tirage au sort peuvent espérer remporter l’un des lots
        proposés par l’IFEF.
      </div>
      <div className='text-title'>Article 8</div>

      <div className='text-style'>
        Après vérification, les personnes éligibles au tirage au sort recevront
        une étiquette sur laquelle elles devront mentionner leur nom et prénoms.
        Elles seront ensuite invitées à déposer l’étiquette dans l’urne
        transparente prévue pour le tirage au sort.
      </div>
      <div className='text-title'>Article 8</div>

      <div className='text-style'>
        Lors du tirage au sort, les étiquettes sont tirées du 3 ème au 1 er prix
        et présentées publiquement. Les gagnants reçoivent le même jour leur
        prix.
      </div>
      <div className='text-title'>Article 9</div>

      <div className='text-style'>
        L’IFEF se réserve le droit de modifier le règlement du concours ou de
        l’annuler si besoin, sans que cela ne fasse l’objet d’une autorisation
        auprès des participants.
      </div>
      <div className='text-title'>Article 10</div>
      <div className='text-style'>
        Le fait de participer au concours implique l’acceptation du présent
        règlement.
      </div>
    </Container>
  );
}

export default Acceptation