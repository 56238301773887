import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { GrHomeRounded } from "react-icons/gr";
import ProgressionBar from "../../components/progressionbar/progressionbar";


import jaw from '../../assets/svg/jaw.svg'

import "bootstrap/dist/css/bootstrap.css";
import "./Modal.css";

const MyModal = ({ handleClose, show, number, winerLoser, displayFinal, questArr, setShow, isElit, progress }) => {
  const navigate = useNavigate();
  const questNum = localStorage.getItem('questNumber')

  const toHome = () => {
    navigate("/scan", { state: number + 1 });
  };

  const nextQuestion = () => {
    navigate("/question/" + questArr[number + 1]);
    setShow(false)
  };

  /* eslint-disable */
  useEffect(() => {
    if (questNum === '10' && show === false) {
      navigate('/scan')
    }
  }, [show])
  /* eslint-enable */

  const subText = () => {
    return (
      <div className="finalSubText">
        <span>Félicitations ! <br />Tu es inscrit(e) au grand tirage au sort pour gagner un cadeau !
        </span>
      </div>
    )
  }

  return (
    <>
      <Modal
        className="mymodal"
        centered
        show={show}
        onHide={handleClose}
        animation={true}
        backdrop={"static"}
        number={number}
        winerLoser={winerLoser}
        displayFinal={displayFinal}
      >
        <Modal.Body className="border-div2">
          {progress === 100 && isElit && <img className="jaw" src={jaw} alt="confétie" />}
          <ProgressionBar progression={progress} />
        </Modal.Body>
        <div className='content'>
          <span>
            {
              isElit && winerLoser ? subText() : progress === 100 ?
                <p>Hmm … ☹️<br /> Pas de chance cette fois-ci, réessaie demain !</p> :
                <p>Cool ! <br />Continue sur cette lancée.</p>
            }
          </span>
        </div>
        <div className="modalBtn">
          {winerLoser ? <Button
            onClick={toHome}
            className="homebtn"
            variant="outline-primary"
          >
            <GrHomeRounded className="homeicon" />
            Accueil
          </Button> : (
            <Button onClick={nextQuestion} className="scanbtn" variant="primary">
              Question suivante
            </Button>
          )}
        </div>
      </Modal>
    </>
  );
}

export default MyModal;