import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { GrHomeRounded } from "react-icons/gr";
import QrReader from "modern-react-qr-reader";

import "./Scan.css";

const Qr = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [data, setData] = useState("");

  const data_new = data.substring(data.lastIndexOf("/") + 1);

  const toBienvenue = () => {
    navigate("/scan");
  };

  const handleScan = (result) => {
    if (result) {
      setData(result);
    }
  };

  const handleError = (error) => {
    console.log(error);
  };

  if (data_new) {
    navigate("/question/" + data_new, { state: location.state });
  }

  return (
    <>
      <div className="display-scan-flex">
        <GrHomeRounded className="homeicon pointer w-icon-home" onClick={toBienvenue} />
        <div className="qr-code">
          <QrReader
            delay={500}
            onError={handleError}
            onScan={handleScan}
            facingMode={"environment"}
          />
        </div>
      </div>
    </>
  );
}

export default Qr;
