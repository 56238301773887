import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { MdOutlineKeyboardArrowDown } from "react-icons/md"
import ReactCountryFlag from "react-country-flag"

import axios from "axios";
import axiosService from "../../services/Axios/axiosServices";
import { SERVER_API, REGISTER_API, QUEST_TAB, ACTIVE_EVENT } from "../../api";
import { setToken } from "../../services/Controller/AuthService";

import FlagModal from "../../components/FlagModal/FlagModal"

import "./Register.css";

const Register = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset
  } = useForm();

  const [emailExist, setEmailExist] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false)
  const [disabledBtn, setDisabledBtn] = useState(true)
  const [show, setShow] = useState(false);
  const [warn, setWarn] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const [selected, setSelected] = useState("");
  const [activeEvent, setActiveEvent] = useState(null);

  const maxRetryCount = 3;

  const registerForm = (data) => {
    let registerData = { ...data, eventIds: [activeEvent] }
    axios
      .post(SERVER_API + REGISTER_API, registerData)
      .then((response) => {
        if (response.status === 201) {
          setLoading(false)
          setToken(response.data?.token);
          getQuestTab()
        }
      })
      .catch((error) => {
        setLoading(false)
        if (error.response.status === 409) {
          setEmailExist(true);
        } else {
          setError(true);
        }
      });
  };

  const getActiveEvent = async () => {
    await axios.get(SERVER_API + ACTIVE_EVENT).then((res) => {
      if (res.data[0]) {
        setActiveEvent(res.data[0]._id)
      } else {
        navigate('/')
      }
    })
  }

  const getQuestTab = () => {
    axiosService
      .get(SERVER_API + QUEST_TAB + activeEvent)
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            localStorage.setItem('quests', res.data)
            localStorage.setItem('questNumber', 0)
            navigate(`/question/${res.data[0]}`);
          }

        }
      }).catch((err) => {
        if (err.code === 'ERR_NETWORK') {
          if (retryCount < maxRetryCount) {
            setTimeout(() => setRetryCount(retryCount + 1), 3000); // Retry after 3 seconds
          } else {
            console.log('Maximum retry count reached. Could not fetch data.');
          }
        }
      });
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onSelect = (code) => {
    setSelected(code)
    handleClose()
  };

  const onSubmit = (data) => {
    setLoading(true)
    const data_ = {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      country: selected,
      gender: data.genre,
      phoneNumber: data.phoneNumber
    };
    registerForm(data_);
  };

  const watchForm = () => {
    let form = watch()
    if (selected !== undefined && form.email !== "" && form.firstName !== "" && form.lastName !== "" && form.regle !== false) {
      setDisabledBtn(false)
    }
  }

  /* eslint-disable */
  useEffect(() => {
    if (retryCount !== 0) {
      getQuestTab()
    }
  }, [retryCount]);

  useEffect(() => {
    getActiveEvent()
  }, [])
  /* eslint-enable */

  useEffect(() => {
    reset()
  }, [reset])

  return (
    <div className="registerContainer">
      <Form autoComplete="off" onChange={watchForm} className="main-register" onSubmit={handleSubmit(onSubmit)}>
        <div className="title-register">
          <span>Inscris-toi ici </span>
        </div>
        <div className="div-flex-register">
          <Form.Group className="align-me" controlId="formGridText">
            <Form.Label>Nom</Form.Label>
            <Form.Control
              type="text"
              className="no-border"
              placeholder="Saisis ton nom"
              {...register("firstName", { required: true, maxLength: 15 })}
            />
          </Form.Group>
          {errors.firstName && (
            <div className="color-red">Merci de saisir ton nom</div>
          )}
          <Form.Group
            className="padding-form-item-top align-me "
            controlId="formGridText"
          >
            <Form.Label>Prénom</Form.Label>
            <Form.Control
              type="text"
              placeholder="Saisis ton prénom"
              className="no-border"
              {...register("lastName", { required: true, maxLength: 15 })}
            />
          </Form.Group>
          {errors.lastName && (
            <div className="color-red">Merci de saisir ton prénom</div>
          )}
          <Form.Group className="padding-form-item-top align-me">
            <Form.Label>Genre</Form.Label>
            <select
              size="lg"
              className="no-border sexSelect"
              name="genre"
              {...register("genre", { required: false })}
            >
              <option>Autre</option>
              <option>Femme</option>
              <option>Homme</option>
            </select>
          </Form.Group>
          {errors.genre && (
            <div className="color-red">Merci de sélectionner ton genre</div>
          )}
          <Form.Group
            className="padding-form-item-top align-me"
            controlId="formGridEmail"
          >
            <div className="country">
              <Form.Label>Choisis ton pays</Form.Label>
              <div onClick={handleShow} className="selectedCountry">
                <ReactCountryFlag className="flag" countryCode={selected} svg />
                <span className="displayedCountry">{selected}</span>
                <MdOutlineKeyboardArrowDown className="arrow-down-style"></MdOutlineKeyboardArrowDown>
              </div>
            </div>
          </Form.Group>
          {errors.country && (
            <div className="color-red">svp saisir votre pays</div>
          )}
          <Form.Group
            className="padding-form-item-top align-me"
            controlId="formGroupEmail"
          >
            <Form.Label>Adresse courriel</Form.Label>
            <Form.Control
              onClick={setWarn}
              type="email"
              placeholder="nom@exemple.com"
              className="no-border"
              {...register("email", {
                required: true,
              })}
            />
          </Form.Group>
          {warn && <span className="text-warning">*Assure-toi que ton adresse courriel est correcte. </span>}
          {errors.email && (
            <div className="color-red">Merci de saisir ton adresse courriel</div>
          )}
          {emailExist ? (
            <div className="color-red">Cette adresse courriel est déjà existante</div>
          ) : null}

          <Form.Group
            className="padding-form-item-top align-me "
            controlId="formGridText"
          >
            <Form.Label>Téléphone</Form.Label>
            <Form.Control
              type="text"
              placeholder="Saisis ton numéro de téléphone"
              className="no-border"
              {...register("phoneNumber", { required: true })}
            />
          </Form.Group>
          {errors.phoneNumber && (
            <div className="color-red">Merci de saisir ton numéro de téléphone</div>
          )}

          <Form.Group
            className="padding-form-item-top"
            controlId="formBasicCheckbox"
          >
            <input
              type="checkbox"
              id="agree"
              {...register("regle", { required: true })}
            />
            <label htmlFor="agree">
              J'ai lu et j'accepte <a href="/accept">les règlements généraux</a>
            </label>
          </Form.Group>
          {errors.regle && (
            <div className="color-red">
              Merci d'accepter les règlements généraux
            </div>
          )}
        </div>
        {error ? (
          <div className="color-red">Une erreur s'est produite, stp de saisir à nouveau tes données</div>
        ) : null}

        <div className="w-button">
          <Button
            color="#0085FF"
            type="submit"
            className="button-principale"
            disabled={loading || disabledBtn}
          >
            {loading ? (
              <i
                className="fa fa-refresh fa-spin"
                style={{ marginRight: "5px" }}
              />
            ) : null}
            COMMENCE TA QUÊTE !
          </Button>
        </div>
      </Form>
      <FlagModal
        show={show}
        handleClose={handleClose}
        selected={selected}
        onSelect={onSelect}
        register={register}
      />
    </div>
  );
}

export default Register;
