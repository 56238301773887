import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "react-bootstrap";
import { BsFillPatchQuestionFill } from "react-icons/bs";
import { BsShare } from 'react-icons/bs';
import ProgressionBar from "../../components/progressionbar/progressionbar";
import SharingModal from "../../components/SharingModal/SharingModal";

import axios from "axios";
import axiosService from "../../services/Axios/axiosServices";
import { ACTIVE_EVENT, SERVER_API, USER_DETAIL } from "../../api";
import { getUserId } from "../../services/Controller/CurrentUserService";

import jaw from "../../assets/svg/jaw.svg";

import "./Bienvenue.css";

const Bienvenue = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const questArr = localStorage.getItem('quests')?.split(',')
  const questNum = localStorage.getItem('questNumber')

  const [show, setShow] = useState(false);
  const [activeEvent, setActiveEvent] = useState(null);
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    gameOver: false,
  });

  const toNextQuestion = () => {
    navigate(`/question/${questArr[+questNum + 1]}`, { state: location.state });
  };

  const getUserData = () => {
    axiosService
      .get(SERVER_API + USER_DETAIL + getUserId())
      .then((response) => {
        if (response.status === 200) {
          setData({
            ...data,
            firstName: response.data?.firstName,
            lastName: response.data?.lastName,
            email: response.data?.email,
            score: response.data?.score,
            progression: response.data?.progression,
            gameOver: response.data?.gameOver,
          });
        }
      });
  };

  const getActiveEvent = async () => {
    await axios.get(SERVER_API + ACTIVE_EVENT).then((res) => {
      if (res.data[0]) {
        setActiveEvent(res.data[0])
      } else {
        navigate('/')
      }
    })
  }

  /* eslint-disable */
  useEffect(() => {
    if (localStorage.getItem("token_ifefo")) {
      getUserData();
      getActiveEvent()
    }
  }, []);
  /* eslint-enable */

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (!localStorage.getItem("token_ifefo")) {
      navigate("/register");
    }
  }, [navigate]);

  return (
    <>
      <div>
        <div className="main">
          <div className="flex-div-image">
            <div className="uploaded-logo">{activeEvent?.logoLeft && <img src={SERVER_API + "uploads/" + activeEvent?.logoLeft} alt={activeEvent?.title} />}</div>
            <div className="uploaded-logo">{activeEvent?.logoRight && <img src={SERVER_API + "uploads/" + activeEvent?.logoRight} alt={activeEvent?.title} />}</div>
          </div>
          <div className="flex-div-bienvenue">
            <div className="div-height">
              <div className="title-principale">
                <div>Salut {data?.firstName} !</div>
                <div className="text-div-1">{data?.email}</div>
              </div>
              <div className="border-div">
                <div className="progress-flex">
                  {data.score >= 40 && data.progression === 100 ? (
                    <img className="jawHome" src={jaw} alt="confétie" />
                  ) : null}
                  <ProgressionBar progression={data.progression} />
                </div>
                <span className="text-progression">Ma progression IFEFo</span>
              </div>
            </div>
            <div className="text-principale">
              {data.score >= 40
                ? <p>Félicitations !  <br /> Tu es inscrit(e) au grand tirage au sort pour gagner un cadeau !</p>
                : data.progression !== 100 ? <p style={{ fontWeight: 400 }}>Répond à la question suivante pour avancer vers ta quête.
                  <br />On te réserve une surprise !</p>
                  : (data.progression === 100 && data.score < 40)
                    ? <p>Dommage ! ☹️ <br /> Tu n’as eu de chance cette-fois ci. <br /> Réessaie demain pour tenter d’entrer au tirage au sort !</p>
                    : null}
            </div>
            <div className="downBtns">
              {+questNum !== 10 &&
                <Button
                  variant="primary"
                  size="lg"
                  className="button-principale homePageBtn pointer"
                  onClick={toNextQuestion}
                >
                  <BsFillPatchQuestionFill size={26} /> Question Suivante
                </Button>}
              <div className="shareBtn" onClick={handleShow}>
                <BsShare />
              </div>
            </div>
          </div>
          <SharingModal
            show={show}
            handleClose={handleClose}
          />
        </div>
      </div>
    </>
  );
}

export default Bienvenue