import React, { useEffect, useState } from 'react';
import CircularBar from 'react-multicolor-circular-progress-bar';
import './progressionbar.css';

const ProgressionBar = (props) => {
  const [firstQuestionColor, setFirstQuestionColor] = useState('#E8F2D3');
  const [secondQuestionColor, setSecondQuestionColor] = useState('#E8F2D3');
  const [thirdQuestionColor, setThirdQuestionColor] = useState('#E2D3E6');
  const [fourthQuestionColor, setFourthQuestionColor] = useState('#E2D3E6');

  const [fifthQuestionColor, setFifthQuestionColor] = useState('#FAD2D4');
  const [sixthQuestionColor, setSixthQuestionColor] = useState('#FAD2D4');
  const [seventhQuestionColor, setSeventhQuestionColor] = useState('#CCECF9');
  const [eighthQuestionColor, setEighthQuestionColor] = useState('#CCECF9');

  const [nignthQuestionColor, setNignthQuestionColor] = useState('#FFF7CC');
  const [tenthQuestionColor, setTenthQuestionColor] = useState('#FFF7CC');

  useEffect(() => {
    if (props && props.progression) {
      if (props.progression >= 10) setFirstQuestionColor('#8CBE22');
      if (props.progression >= 20) setSecondQuestionColor('#8CBE22');

      if (props.progression >= 30) setThirdQuestionColor('#702283');
      if (props.progression >= 40) setFourthQuestionColor('#702283');

      if (props.progression >= 50) setFifthQuestionColor('#E51D26');
      if (props.progression >= 60) setSixthQuestionColor('#E51D26');

      if (props.progression >= 70) setSeventhQuestionColor('#009FE3');
      if (props.progression >= 80) setEighthQuestionColor('#009FE3');

      if (props.progression >= 90) setNignthQuestionColor('#FFD500');
      if (props.progression >= 100) setTenthQuestionColor('#FFD500');
    }
  }, [props]);

  return (
    <>
      <CircularBar
        scale={2}
        angleTransition={[36, 72, 108, 144, 180, 216, 252, 288, 324, 360]}
        colors={[
          firstQuestionColor,
          secondQuestionColor,
          thirdQuestionColor,
          fourthQuestionColor,

          fifthQuestionColor,
          sixthQuestionColor,
          seventhQuestionColor,
          eighthQuestionColor,

          nignthQuestionColor,
          tenthQuestionColor,
        ]}
        stroke={{ color: 'transparent', width: 20 }}
        percent={{
          value: 0,
          color: 'transparent',
        }}
      />
      <span className='progressionValue'>
        {props?.progression ? props?.progression : 0} %
      </span>
    </>
  );
}

export default ProgressionBar;
