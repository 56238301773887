import axios from "axios";
import { getToken } from "../Controller/AuthService"

/* Add a request interceptor */
axios.interceptors.request.use(
  (config) => {
    //get token
    const token = getToken();
    // Do something before request is sent
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    } else return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

/* export axios requests */
const axiosService = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch,
};
export default axiosService;
