export const DisplyPopupFinal = (
  count,
  totalResponses,
  score,
  setWinLoser,
  setDisplayFinal
) => {
  if (score === 40) {
    setDisplayFinal(1);
  } else if(score < 40){
    setDisplayFinal(0);
  }else if(score > 40){
    setDisplayFinal(0);
  }
  if (count === totalResponses) {
    setWinLoser(true);
  }
};