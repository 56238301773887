import { useState } from 'react'

import Modal from "react-bootstrap/Modal";

import countries from "./countries.json"
import ReactCountryFlag from 'react-country-flag';

import './FlagModal.css'

const FlagModal = (props) => {
    const { handleClose, show, register, onSelect } = props;
    const [filteredFlags, setFilteredFlags] = useState(countries);

    const filterCountries = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            setFilteredFlags(countries)
            const results = countries.filter((country) => {
                return country.name.toLowerCase().startsWith(keyword.toLowerCase());
            });
            setFilteredFlags(results);
        } else {
            setFilteredFlags(countries);
        }
    };

    return (
        <>
            <Modal
                className="mymodal"
                centered
                show={show}
                onHide={handleClose}
                animation={true}
                onBackdropClick={handleClose}
            >
                <Modal.Body className='flagModalBody'>
                    <div {...register("country", { required: false })} className='countries' >
                        <input id="search-box" placeholder='Recherche' onChange={filterCountries} />
                        {
                            filteredFlags.map((country) => {
                                return (
                                    <div key={country.code} className='oneCountry' onClick={(e) => onSelect(country.code)}>
                                        <ReactCountryFlag className='flag' countryCode={country.code} svg />
                                        <span>{country.name}</span>
                                    </div>
                                )
                            })
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default FlagModal