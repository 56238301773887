import {
  BrowserRouter as Router,
  Route,
  Routes as Switch,
} from "react-router-dom";

import Acceptation from "./pages/Acceptation/Acceptation";
import Principale from "./pages/PrincipalePage/Principale";
import Register from "./pages/Register/Register";
import Question from "./pages/Question/Question";
import Background from "./components/background/Background";
import Bienvenue from "./pages/Bienvenue/Bienvenue";
import Qr from "./pages/ScanPage/Scan";

const App = () => {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/" element={<><Principale /></>}></Route>
          <Route exact path="/accept" element={<><Background /><Acceptation /> </>}> </Route>
          <Route path="/question/:id" element={<Question />}></Route>
          <Route path="/scan" element={<><Background /><Bienvenue /></>}></Route>
          <Route path="/qr" element={<Qr />}></Route>
          <Route exact path="/register" element={<><Background /><Register /></>}> </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
