import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { Button, Form } from "react-bootstrap";

import MyModal from "../../components/Modal/Modal";
import LinearProgressbar from "../../components/LinearProgressbar/LinearProgressbar";
import { SERVER_API, QUESTION_ID, ANSWER, USER_DETAIL, ACTIVE_EVENT } from "../../api";

import axios from "axios";
import axiosService from "../../services/Axios/axiosServices";
import { getToken } from "../../services/Controller/AuthService";
import { decodeToken } from "../../services/Controller/CurrentUserService";
import { DisplyPopupFinal } from "../../services/Controller/ModalService";

import backgroundImageFull from '../../assets/svg/bg-questions.svg'
import Loader from "../../assets/Loader.png";

import "./Question.css";

const Question = () => {
  const navigate = useNavigate();

  const questArr = localStorage.getItem('quests').split(',')
  const questNum = localStorage.getItem('questNumber')

  const [questNumber, setQuestNumber] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [progress, setProgress] = useState(1);
  const [loading, setLoading] = useState(true);
  const [answered, setAnswered] = useState(false);
  const [show, setShow] = useState(false);
  const [question, setQuestion] = useState({});
  const [winerLoser, setWinLoser] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [displayFinal, setDisplayFinal] = useState(0);
  const [isElit, setIsElit] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const [activeEvent, setActiveEvent] = useState(null);
  const maxRetryCount = 3; // Set the maximum number of retries here

  const getUserData = () => {
    const token = localStorage.getItem("token_ifefo");
    const user = decodeToken(token)
    axiosService
      .get(SERVER_API + USER_DETAIL + user.id)
      .then((response) => {
        if (response && response.status === 200) {
          if (response.data) {
            setProgress(response.data.progression)
            setQuestNumber(response.data.idAnsweredQuestions);
            setIsElit(response.data.isElit)
          }
        }
      });
  };

  const {
    register,
    handleSubmit,
    watch,
    resetField,
    formState: { errors },
  } = useForm();

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const params = useParams();
  const token_exist = getToken();

  const postResponse = (data) => {
    axiosService
      .post(SERVER_API + ANSWER, data)
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem('questNumber', questNumber.length + 1)
          setLoadingButton(false);
          DisplyPopupFinal(
            response?.data?.count,
            response?.data?.totalResponses,
            response?.data?.score,
            setWinLoser,
            setDisplayFinal
          );
          getUserData()
          setAnswered(false);
          handleShow();
          resetField('input', false)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getQuestion = (params) => {
    setLoading(true)
    axiosService
      .get(SERVER_API + QUESTION_ID + params)
      .then((response) => {
        axiosService.get(SERVER_API + ANSWER + params).then((res) => {
          setLoading(false)
          setQuestion({
            ...question,
            content: response?.data?.content,
            id: response?.data?._id,
            message: response?.data?.message,
            empty: response?.status,
            options: arrayOptions(res?.data),
          });
        })
      })
      .catch((err) => {
        if (err.code === 'ERR_NETWORK') {
          if (retryCount < maxRetryCount) {
            setLoading(true)
            setTimeout(() => setRetryCount(retryCount + 1), 3000); // Retry after 3 seconds
          } else {
            setLoading(false)
            console.log('Maximum retry count reached. Could not fetch data.');
          }
        }
      });
  };

  const arrayOptions = (data) => {
    const newArrayOfAnswers = data?.map(
      ({ isValid, ...keepAttrs }) => keepAttrs
    );
    const arr = newArrayOfAnswers?.map((object) => { return { id: object._id, content: object?.content } });
    return arr;
  }

  const Loading = () => {
    if (!token_exist && !question) {
      navigate("/register");
    }
  };

  /* eslint-disable */
  useEffect(() => {
    getUserData()
    getQuestion(questArr[questNum]);
    Loading();
  }, [params, retryCount]);

  /* eslint-enable */

  const findIdByContent = (array, targetContent) => {
    const foundObject = array.find(obj => obj.content === targetContent);
    return foundObject ? foundObject.id : null;
  };

  const onSubmit = (data) => {
    setLoadingButton(true);

    const data_ = {
      questionId: questArr[questNum],
      answerId: findIdByContent(question.options, data.input),
    };

    postResponse(data_);
  };

  const toScan = () => {
    navigate("/scan");
  };

  const handleDisableBtn = () => {
    let input = watch();
    if (input.input !== null) {
      setDisabledBtn(false);
    }
  };

  const getBadge = (progress) => {
    switch (true) {
      case (progress >= 0 && progress <= 10):
        return 1;
      case (progress >= 20 && progress <= 30):
        return 2;
      case (progress >= 40 && progress <= 50):
        return 3;
      case (progress >= 60 && progress <= 70):
        return 4;
      case (progress >= 80 && progress <= 90):
        return 5;
      default:
        return 5;
    }
  }

  const getActiveEvent = async () => {
    await axios.get(SERVER_API + ACTIVE_EVENT).then((res) => {
      if (res.data[0]) {
        setActiveEvent(res.data[0])
      } else {
        navigate('/')
      }
    })
  }

  /* eslint-disable */
  useEffect(() => {
    getActiveEvent()
    if (+questNum + 1 === +questNumber.length) {
      localStorage.setItem('questNumber', +questNumber.length)
      getQuestion(questArr[questNumber + 1])
    }
  }, [questNumber])
  /* eslint-enable */

  return (
    <div className="container3">
      <div className="questionsHeader">
        <div className="svgContainer">
          <img className="questionTop" src={backgroundImageFull} alt="" />
        </div>
      </div>
      <div className="logos">
        <div className="uploaded-logo">{activeEvent?.logoLeft && <img src={SERVER_API + "uploads/" + activeEvent?.logoLeft} alt={activeEvent?.title} />}</div>
        <div className="uploaded-logo">{activeEvent?.logoRight && <img src={SERVER_API + "uploads/" + activeEvent?.logoRight} alt={activeEvent?.title} />}</div>
      </div>
      {loading ? (
        <div className="loader">
          <img src={Loader} className="circle" alt="loader" />
        </div>
      ) : (
        <div className="container2">
          <Form onChange={handleDisableBtn} onSubmit={handleSubmit(onSubmit)}>
            <div className="quest">
              <div>
                <img alt="badge" className="badge-question" src={require(`../../assets/svg/lvl-${getBadge(progress)}.svg`)} />
              </div>
              <span className={`questID text-lvl-${getBadge(progress)}`}>Question {questNumber.length + 1 < 10 ? 0 : ''}{questNumber.length + 1}.</span>
              {answered ? (
                <div className="loader">
                  <img className="circle" src={Loader} alt="loader" />
                </div>
              ) : (
                <div>
                  <span className="questContent">{question.content}</span>
                  <div className="options">
                    {question.options
                      ? question.options.map((opt, index) => {
                        return (
                          <div key={index} className="inputs">
                            <input
                              id={index}
                              name="input"
                              type="radio"
                              value={opt.content}
                              {...register("input", { required: true })}
                            />
                            <label htmlFor={index}>{opt.content}</label>
                          </div>
                        );
                      })
                      : null}
                    {errors.input && (
                      <div className="color-red">
                        Vous devez d'abord selectionner une réponse
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="w-button">
              {question && question.message === "409" ? (
                <div className="color-red text-error-question">
                  Vous avez déja répondu à cette question
                </div>
              ) : question.empty === 404 ? (
                <div className="color-red text-error-question">cette question n'existe pas</div>
              ) : question.empty === 403 ? (
                <div className="color-red text-error-question">
                  Cette question n'est pas prévue pour aujourd'hui
                </div>
              ) : (
                <Button
                  variant="primary"
                  type="submit"
                  className="button-principale"
                  disabled={loadingButton || disabledBtn}
                >
                  {loadingButton ? (
                    <i
                      className="fa fa-refresh fa-spin"
                      style={{ marginRight: "5px" }}
                    />
                  ) : null}
                  VALIDER
                </Button>
              )}
              {question && (question.message === "409" || question.empty === 403 || question.empty === 404) ? <Button
                variant="primary"
                type="submit"
                className="button-principale"
                onClick={toScan}
              >
                Page d'accueil
              </Button> : null}
            </div>
          </Form>
        </div>
      )}
      <MyModal
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
        number={questNumber.length}
        winerLoser={winerLoser}
        displayFinal={displayFinal}
        questArr={questArr}
        setShow={setShow}
        isElit={isElit}
        progress={progress}
      />
      <LinearProgressbar progress={progress} />
    </div>
  );
};

export default Question;
