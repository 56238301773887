import jwtDecode from "jwt-decode";

/* get Current Current User */
export const decodeToken = (token) => {
  return token ? jwtDecode(token) : null;
};

/* get Current Current User */
export const getUser = () => {
  const token = localStorage.getItem("token_ifefo");
  return token ? jwtDecode(token).id : null;
};

/* get Id Current User */
export const getUserId = () => {
  const id = getUser();
  return id;
};