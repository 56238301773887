import React from 'react'
import Modal from "react-bootstrap/Modal";
import { ShareSocial } from 'react-share-social'
import './SharingModal.css'

const SharingModal = (props) => {
    const { handleClose, show } = props;

    // the sharing component classnames keep changing and so we have to do an inline styling
    const style = {
        backgroundColor: 'unset',
        color: 'white',
        width: "100%",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    };

    return (
        <>
            <Modal
                className="shareModal"
                centered
                show={show}
                onHide={handleClose}
                animation={true}
                onBackdropClick={handleClose}
            >
                <Modal.Body className='sharingModalBody'>
                    <ShareSocial
                        style={style}
                        className='sharingContent'
                        title={'Invite tes ami(e)s à jouer à IFEFo'}
                        url="https://ifefo.app/"
                        socialTypes={['facebook', 'twitter', 'linkedin']}
                    />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SharingModal