import splashTop from "./splashTop.svg"
import yellowSquare from "./yellowSquare.svg"
import downWave from "./downWave.svg"

import './Background.css'

const Background = () => {

    const pathname = window.location.pathname

    const loginBackground = () => {
        return (
            <div className="backgroundHeader">
                <img className="yellowBackground" src={splashTop} alt="" />
            </div>
        )
    }

    const questionBackground = () => {
        return (
            <div className="questBack">
                <img className="questSplashTop" src={splashTop} alt="" />
                <img className="questYellowSquare" src={yellowSquare} alt="" />
                <img className="questDownWave" src={downWave} alt="" />
            </div>
        )
    }

    return (
        pathname.includes('question') ? questionBackground() : loginBackground()
    )
}

export default Background